<template>
  <nav :class="`menu ${inline ? '-inline' : ''} `">
    <ul class="menu_items" v-if="items && items.length > 0">
      <li v-for="item in items ?? []" class="menu_item">
        <Link class="menu_link" v-if="item.link" v-bind="{
          url: path(item.link.url, {
            type: item.link.type,
          }),
          target: item.target,
          title: item.link.title,
          text: item.text,
        }" />
        <span v-else>
          {{ item.text }}
        </span>

        <Menu :inline="inline" :items="item.items" v-if="item.items?.length" />
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import Link from '~/components/ui/link/link.vue';
import './menu.css';
import { type TMenu } from './menu.type';

const { path } = usePath();

const props = withDefaults(defineProps<TMenu>(), {})

</script>